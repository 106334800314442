import { create } from "zustand";

type User = {
  email: string;
  icon: string;
  team: string;
  telegram: string;
  userId: number;
  wallet: number;
  bonusWallet: number;
  isAdmin: boolean;
  creatives: [];
};

interface AuthState {
  user: User | null;
  isUserAuthenticated: boolean;
  setUser: (user: User | null) => void;
  setAuthenticated: (isAuthenticated: boolean) => void;
  clearAuthData: () => void;
  updateUserData: (user: Partial<User>) => void;
}

export const useAuthStore = create<AuthState>((set, get) => {
  const savedUserString = typeof window !== "undefined" && localStorage.getItem("userData");
  const savedUser = savedUserString ? JSON.parse(savedUserString || "{}") : null;
  const isUserAuthenticated = !!savedUser;

  return {
    user: savedUser,
    isUserAuthenticated,
    setUser: (user) => {
      set({ user, isUserAuthenticated: !!user });
      if (user) {
        localStorage.setItem("userData", JSON.stringify(user));
      } else {
        localStorage.removeItem("userData");
      }
    },
    updateUserData: (user) => {
      const currentUser = get().user;
      if (!currentUser) return;

      const updatedUser: User = {
        email: currentUser.email,
        icon: currentUser.icon,
        team: currentUser.team,
        telegram: currentUser.telegram,
        userId: currentUser.userId,
        wallet: currentUser.wallet,
        bonusWallet: currentUser.bonusWallet,
        isAdmin: currentUser.isAdmin,
        creatives: currentUser.creatives,
        ...user,
      };

      set({ user: updatedUser });
      localStorage.setItem("userData", JSON.stringify(updatedUser));
    },
    setAuthenticated: (isAuthenticated) => {
      set({ isUserAuthenticated: isAuthenticated });
    },
    clearAuthData: () => {
      set({ user: null, isUserAuthenticated: false });
      localStorage.removeItem("authToken");
      localStorage.removeItem("userData");
    },
  };
});
