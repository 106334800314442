import React, { ChangeEvent, useState } from "react";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Box,
  FormControlLabel,
  Checkbox,
  CheckboxProps,
  styled,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useAppStore, useErrorAppStore } from "store/appStore";
import useCategories from "mokcup/category";
import { AppDataMockup } from "interfaces/mockupApp";
import { useTranslation } from "react-i18next";
import CustomInput from "components/Input";
import CustomSelectComponent from "components/Select";
import { translateText } from "utils/translationService";
import MultiSelect, { CountryOption } from "components/MultiSelect";
import { MultiValue } from "react-select";
import { categoryKeys, descriptions } from "./content";
import ShuffleOutlinedIcon from "@mui/icons-material/ShuffleOutlined";

interface IDescription {
  languages: any;
}

export interface ILang {
  name: string;
  code: string;
}

const CustomCheckbox = styled(Checkbox)<CheckboxProps>(() => ({
  color: "#C9D0D8",
  "&.Mui-checked": {
    color: "#F4701B",
  },
  "&:hover": {
    backgroundColor: "rgba(244, 112, 27, 0.08)",
  },
}));

const CustomFormControlLabel = styled(FormControlLabel)(() => ({
  "& .MuiFormControlLabel-label": {
    fontFamily: "var(--cygreRegular), sans-serif",
    fontSize: "14px",
    color: "#2E263D",
  },
}));

export default function Description({ languages }: IDescription) {
  const { appData, saveAppData } = useAppStore();
  const categories = useCategories();
  const { t, i18n } = useTranslation();
  const { errors } = useErrorAppStore();
  const [description, setDescription] = useState("");

  const getRandomDescription = async (selectedCategories?: string[] | string) => {
    const currentLanguage = i18n.language as "en" | "ru" | "uk";
    const categoryMap = categoryKeys[currentLanguage] || categoryKeys.en;

    const category = selectedCategories && selectedCategories.length ? selectedCategories : appData.category;
    const selectedCategoryDisplay = Array.isArray(category) ? (category.length > 0 ? category[0] : "") : category;

    // Преобразуем отображаемое значение в ключ с учетом текущего языка
    const selectedCategoryKey = categoryMap[selectedCategoryDisplay as keyof typeof categoryMap] || "";

    if (selectedCategoryKey && descriptions[selectedCategoryKey]) {
      const descrByCategory = descriptions[selectedCategoryKey];
      const translateKey = `randomDescr_${selectedCategoryKey}`;
      const translatedText = await translateText({ [translateKey]: descrByCategory }, appData.language as string);
      setDescription(translatedText[translateKey] || descrByCategory);
    } else {
      console.warn(`Description for category '${selectedCategoryDisplay}' not found`);
      setDescription("content is empty");
    }
  };

  const handleInputChange = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    field: keyof AppDataMockup
  ) => {
    const { value } = event.target;
    saveAppData({ [field]: value });
  };

  const handleLanguageChange = async (event: ChangeEvent<{ value: unknown }>) => {
    const { value } = event.target;
    const textsToTranslate = {
      author: appData.author,
      description: appData.description,
      developerSite: appData.developerSite,
      developerEmail: appData.developerEmail,
      adressCompany: appData.adressCompany,
      linkPolicy: appData.linkPolicy,
      descriptionPC: appData.descriptionPC,
      youtubeVideoLink: appData.youtubeVideoLink,
    };

    const translatedTexts = await translateText(textsToTranslate, value as string);
    saveAppData({ ...translatedTexts, language: value as string });
  };

  const handleCheckboxChange = (event: any, field: keyof AppDataMockup) => {
    const { checked } = event.target;
    saveAppData({ [field]: checked });
  };

  const handleMultiSelectChange = (selected: MultiValue<CountryOption>, field: keyof AppDataMockup) => {
    const selectedCategories = selected.map((option) => option.value);
    saveAppData({ [field]: selectedCategories as string[] });
    if (field === "category") {
      getRandomDescription(selectedCategories);
    }
  };

  return (
    <Accordion
      sx={{
        boxShadow: "none",
        border: "1px solid #EEEEEE",
        borderRadius: "8px",
      }}
    >
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Typography sx={{ fontFamily: "var(--cygreRegular), sans-serif" }}>{t("main")}</Typography>
      </AccordionSummary>
      <Box sx={{ maxWidth: "480px" }}>
        <AccordionDetails>
          <Box sx={{ paddingTop: "24px" }}>
            <CustomInput
              margin="normal"
              required
              fullWidth
              id="author"
              height="40px"
              placeholder={t("developer")}
              name="author"
              label={t("author")}
              value={appData.author}
              onChange={(e) => handleInputChange(e, "author")}
              customBgColor="#F6F7F9"
              error={!!errors.author}
              helperText={errors.author}
            />
          </Box>
          <Box sx={{ paddingTop: "24px" }}>
            <CustomSelectComponent
              options={
                languages &&
                languages.map((lang: ILang) => ({
                  value: lang.code,
                  label: lang.name,
                }))
              }
              placeholder={t("selectlanguage")}
              title={t("language")}
              value={appData.language}
              onChange={(e: any) => handleLanguageChange(e)}
              noBorder
              backgroundColor="#F6F7F9"
            />
          </Box>
          <Box sx={{ paddingTop: "24px" }}>
            <MultiSelect
              options={categories}
              placeholder={t("entercategory")}
              title={t("category")}
              value={appData.category.map((category) => ({
                value: category,
                label: category,
              }))}
              onChange={(e) => handleMultiSelectChange(e, "category")}
              noBorder
              backgroundColor="#F6F7F9"
            />
          </Box>
          <Box sx={{ paddingTop: "24px" }}>
            <CustomInput
              margin="normal"
              required
              fullWidth
              id="size"
              height="40px"
              placeholder={t("entersize")}
              name="size"
              label={t("size")}
              value={appData.size}
              error={!!errors.size}
              helperText={errors.size}
              onChange={(e) => handleInputChange(e, "size")}
              customBgColor="#F6F7F9"
              iconEnd={<Typography sx={{ color: "#B4B1B9", fontSize: "14px" }}>{t("mb")}</Typography>}
            />
          </Box>
          <CustomInput
            margin="normal"
            required
            fullWidth
            id="description"
            placeholder={t("enterdescription")}
            multiline
            rows={4}
            label={t("description")}
            value={appData.description}
            onChange={(e) => handleInputChange(e, "description")}
            customBgColor="#F6F7F9"
          />
          <Box>
            <CustomFormControlLabel
              value={appData.ad}
              onChange={(e) => handleCheckboxChange(e, "ad")}
              control={<CustomCheckbox />}
              label={t("thereisanad")}
            />
          </Box>
        </AccordionDetails>
      </Box>
    </Accordion>
  );
}
