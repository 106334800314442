import { create } from "zustand";
import { PushDataMockup } from "interfaces/mockupApp";

interface AppState {
  pushData: PushDataMockup;
  savePushData: (newData: Partial<PushDataMockup>) => void;
  clearPushData: () => void;
  resetPushData: () => void;
  getDefaultPushData: () => PushDataMockup;
}

export const emptyPushData: PushDataMockup = {
  pushName: "",
  standartDate: "",
  standartTime: "",
  useUserTimezone: false,
  intervalDates: {},
  headings: "",
  contents: "",
  notifyAgain: false,
  awaitResponse: false,
  silentMode: false,
  geo: [],
  pwaId: "",
  icon: "",
  origin: "",
  delivery: "now",
  language: "",
  completedAt: 0,
  url: "",
};

const usePushStore = create<AppState>((set) => ({
  pushData: { ...emptyPushData },
  savePushData: (newData) =>
    set((state) => {
      const updatedPushData = { ...state.pushData, ...newData };

      return { pushData: updatedPushData };
    }),
  clearPushData: () => set({ pushData: { ...emptyPushData } }),
  resetPushData: () => set({ pushData: { ...emptyPushData } }),
  getDefaultPushData: () => emptyPushData,
}));

export { usePushStore };
