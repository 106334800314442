import React from "react";
import "./CookieBanner.scss";
import { useState } from "react";

export const CookieBanner = () => {
  const storageSubmmited = localStorage.getItem( "cookieBannerSubmitted" ) || false;
  const [submitted, setSubmitted] = useState( Boolean( storageSubmmited ) );

  const submitCookies = () => {
    
    localStorage.setItem( "cookieBannerSubmitted", "true" );
    setSubmitted( true );
  };

  return !submitted ? (
    <div className={ "cookieBanner" }>
      <div className={ "cookieBannerInner" }>
        <p>Вы согласны с использованием файлов cookies?</p>
        <div className={ "cookieButtons" }>
          <button className={ "agree" } onClick={ submitCookies }>
            Согласен
          </button>
          <button className={ "disagree" } onClick={ submitCookies }>
            Не согласен
          </button>
        </div>
      </div>
    </div>
  ) : null;
};
