import { Grid } from "@mui/material";
import Deps from "assets/icons/Deps";
import Installs from "assets/icons/Installs";
import Opens from "assets/icons/Opens";
import Regs from "assets/icons/Regs";
import Ring from "assets/icons/Ring";
import Unique from "assets/icons/Unique";
import Preloader from "components/GlobalPreloader/Preloader";
import WidgetCard from "components/WidgetCard";
import useFetch from "hooks/useFetch";
import { useEffect, useState } from "react";
import Cancel from "assets/icons/Cancel";

export const getToken = () => {
  if (typeof window !== "undefined") {
    return localStorage.getItem("authToken") || "";
  }
};

const StatisticPushWidget = () => {
  const token = getToken();
  const {
    triggerFetch: getOverallStats,
    data: stats,
    loading,
  } = useFetch({
    url: "push/stats/overall",
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  });

  useEffect(() => {
    void getOverallStats();
  }, []);

  const totalCTR =
    stats?.totalClicks && stats?.totalDelivered ? ((stats?.totalClicks / stats?.totalDelivered) * 100).toFixed(0) : 0;

  return (
    <>
      <Grid
        container
        spacing={2}
        sx={{
          position: "relative",
          flexWrap: { xs: "nowrap", sm: "wrap" },
          width: { xs: `calc(100vw * 2.5)`, sm: "100%" },
          marginBottom: "24px",
        }}
      >
        {loading && (
          <Preloader
            customStyles={{
              position: "absolute",
              top: "0",
              left: "0",
              width: "100%",
              height: "100%",
              padding: "0",
              margin: "0",
              border: 0,
              background: "rgba(255, 255, 255, 0.6)",
            }}
          />
        )}
        <Grid item xs={7} sm={12 / 3} lg={12 / 4} xl={12 / 5}>
          <WidgetCard title={"pushOverallStatsTotalTitle"} value={stats?.totalSent || 0} icon={<Unique />} />
        </Grid>
        <Grid item xs={7} sm={12 / 3} lg={12 / 4} xl={12 / 5}>
          <WidgetCard title={"pushOverallStatsDeliveredTitle"} value={stats?.totalDelivered || 0} icon={<Ring />} />
        </Grid>

        <Grid item xs={7} sm={12 / 3} lg={12 / 4} xl={12 / 5}>
          <WidgetCard title={"pushOverallStatsViewedTitle"} value={stats?.totalViews || 0} icon={<Opens />} />
        </Grid>
        <Grid item xs={7} sm={12 / 3} lg={12 / 4} xl={12 / 5}>
          <WidgetCard title={"pushOverallStatsClickedTitle"} value={stats?.totalClicks || 0} icon={<Installs />} />
        </Grid>
        <Grid item xs={7} sm={12 / 3} lg={12 / 4} xl={12 / 5}>
          <WidgetCard
            title={"pushOverallStatsClosesTitle"}
            value={stats?.totalCloses || 0}
            icon={
              <div
                style={{
                  width: "56px",
                  height: "56px",
                  backgroundColor: "rgba(176,176,176,0.48)",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  borderRadius: "8px",
                }}
              >
                <Cancel color={"#696969"} width={"40px"} height={"40px"} />
              </div>
            }
          />
        </Grid>
        <Grid item xs={7} sm={12 / 3} lg={12 / 4} xl={12 / 5}>
          <WidgetCard title={"pushOverallStatsCTRTitle"} value={`${totalCTR}%`} icon={<Regs />} />
        </Grid>
      </Grid>
    </>
  );
};

export default StatisticPushWidget;
