import { create } from "zustand";

interface IUserToDelet {
  id: string;
  email: string;
  telegram: string;
}

interface ModalState {
  isOpen: boolean;
  isDomainModalOpen: boolean;
  isUserDeleteModalOpen: boolean;
  isPromocodeDeleteModalOpen: boolean;
  pagePath: string | null;
  userToDelete: IUserToDelet | null;
  promocodeToDelete: string | null;
  openModal: () => void;
  closeModal: () => void;
  openDomainModal: () => void;
  openUserDeleteModal: () => void;
  openPromocodeDeleteModal: () => void;
  handlePathChange: (path: string | null) => void;
  handleDeleteUserChange: (user: IUserToDelet | null) => void;
  handleDeletePromocodeChange: (promocode: string | null) => void;
}

const useCautionStore = create<ModalState>((set) => ({
  isOpen: false,
  isDomainModalOpen: false,
  isUserDeleteModalOpen: false,
  isPromocodeDeleteModalOpen: false,
  pagePath: null,
  userToDelete: null,
  promocodeToDelete: null,
  openModal: () => set({ isOpen: true }),
  openDomainModal: () => set({ isDomainModalOpen: true }),
  openUserDeleteModal: () => set({ isUserDeleteModalOpen: true }),
  openPromocodeDeleteModal: () => set({ isPromocodeDeleteModalOpen: true }),
  closeModal: () =>
    set({
      isOpen: false,
      isDomainModalOpen: false,
      isUserDeleteModalOpen: false,
      isPromocodeDeleteModalOpen: false,
    }),
  handlePathChange: (path) => set({ pagePath: path }),
  handleDeleteUserChange: (user) => set({ userToDelete: user }),
  handleDeletePromocodeChange: (promocode) => set({ promocodeToDelete: promocode }),
}));

export default useCautionStore;
