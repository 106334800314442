import { Dispatch, SetStateAction } from "react";
import { useEffect, useState } from "react";
import { Box, CircularProgress, Fab } from "@mui/material";
import useCautionStore from "store/cautionStore";
import { useTranslation } from "react-i18next";
import { BootstrapTooltip } from "components/PWATabs/mainTab/CommentsTable";
import DeleteIcon from "@mui/icons-material/Delete";
import AutorenewIcon from "@mui/icons-material/Autorenew";
import CheckIcon from "@mui/icons-material/Check";
import useFetch from "hooks/useFetch";
import { getToken } from "utils/getToken";
import { toast } from "react-toastify";

interface IActionButtonsProps {
  promocodeId: string;
  promocodeStatus: string;
  handleCurrentStatusChange: Dispatch<SetStateAction<boolean>>;
}

export default function PromocodeActionButtons({
  promocodeId,
  promocodeStatus,
  handleCurrentStatusChange,
}: IActionButtonsProps) {
  const { openPromocodeDeleteModal, handleDeletePromocodeChange } = useCautionStore();
  const [isPromocodeActive, setIsPromocodeActive] = useState(false);
  const [success, setSuccess] = useState(false);
  const [loading, setLoading] = useState(false);
  const token = getToken();
  const { t } = useTranslation();

  const { triggerFetch: changePromocodeStatus } = useFetch({
    url: `admin/promocodes/${promocodeId}/status`,
    method: "PATCH",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  const handleStatus = async (event: React.MouseEvent<HTMLButtonElement>) => {
    setLoading(true);
    const customBody = { status: isPromocodeActive ? "inactive" : "active" };
    try {
      await changePromocodeStatus(customBody);

      setTimeout(() => {
        setIsPromocodeActive((prevStatus) => !prevStatus);
        setSuccess(true);
        handleCurrentStatusChange(true);
        toast.success(t("successfulPromocodeStatusChange"));

        setTimeout(() => {
          setSuccess(false);
        }, 5500);
      }, 2000);
    } catch (error) {
      toast.error(t("unsuccessfulPromocodeStatusChange"));
    } finally {
      setTimeout(() => {
        setLoading(false);
      }, 2000);
    }
  };

  const handleDelete = async (event: React.MouseEvent<HTMLDivElement>) => {
    openPromocodeDeleteModal();
    handleDeletePromocodeChange(promocodeId);
  };

  const statusButtonSx = {
    ...(success
      ? {
          width: "26px",
          height: "26px",
          minHeight: "26px",
          bgcolor: "#16B364",
          "&:hover": {
            bgcolor: "#16B364",
          },
        }
      : {
          width: "26px",
          height: "26px",
          minHeight: "26px",
          bgcolor: "transparent",
          color: "#6D6777",
          "&:hover": {
            bgcolor: "transparent",
          },
        }),
  };

  useEffect(() => {
    if (promocodeStatus === "active") {
      setIsPromocodeActive(true);
    }
  }, []);

  return (
    <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", gap: "8px" }}>
      <BootstrapTooltip title={t("changepromocodestatus")} placement="top">
        <Box sx={{ position: "relative" }}>
          <Fab id={promocodeId} aria-label="save" color="primary" sx={statusButtonSx} onClick={handleStatus}>
            {success ? <CheckIcon /> : <AutorenewIcon />}
          </Fab>
          {loading && (
            <CircularProgress
              size={28}
              sx={{
                color: "#16B364",
                position: "absolute",
                top: -1,
                left: -1,
                zIndex: 1,
              }}
            />
          )}
        </Box>
      </BootstrapTooltip>

      <BootstrapTooltip title={t("delete")} placement="top">
        <Box
          sx={{
            display: "flex",
            cursor: "pointer",
          }}
          onClick={handleDelete}
        >
          <DeleteIcon sx={{ color: "#6D6777" }} />
        </Box>
      </BootstrapTooltip>
    </Box>
  );
}
