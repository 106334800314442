import styled from "@emotion/styled";
import { Typography, Box, Grid, Card, CardContent } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import ImageIcon from "assets/icons/ImageIcon";
import ActionButton from "components/ActionButton";
import { IPwa } from "interfaces/pwas";
import showPreviewIcon from "utils/showPreviewImage";
import { useNavigate } from "react-router";
import { RenderPWAStatus } from "components/PwaList/RenderPWAStatus";
import MenuTableButton from "components/Table/MenuTableButton";
import { getCountryFlags } from "../../utils";
import { fetchCountries } from "../../utils/fetchCountries";

interface PwaListTableProps {
  filteredItems: IPwa[];
  getPwas: () => void;
}

export const PWACardsView = ({ filteredItems, getPwas }: PwaListTableProps) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const countries = fetchCountries();
  return (
    <Grid container spacing={0.2} rowSpacing={3} sx={{ marginLeft: "0px" }}>
      {filteredItems?.length === 0 ? (
        <Grid item xs={12}>
          <Typography variant="subtitle2" fontWeight={600}>
            {t("noitems")}
          </Typography>
        </Grid>
      ) : (
        filteredItems?.map(({ _id, appName, creatives, categoryVertical, domain, status, metaTags, geo }: IPwa) => {
          const pwaCountries = geo?.map(
            (country) => countries.find((countryItem) => countryItem.code === country)?.flag
          );
          const trackerValue = !geo?.length ? "Multi Geo" : pwaCountries.join(" ") || "Multi Geo";
          return (
            <Grid item xs={12} lg={12 / 2} xl={12 / 3} key={_id}>
              <Card
                key={_id}
                tabIndex={-1}
                sx={{
                  borderRadius: "8px",
                  margin: "8px",
                  boxShadow: "0px 0px 4px 0px rgba(165, 163, 174, 0.3)",
                  height: "100%",
                }}
              >
                <CardContent
                  sx={{
                    boxShadow: "none",
                    display: "flex",
                    flexDirection: "column",
                    height: "100%",
                    paddingBottom: "16px !important",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "flex-start",
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "flex-start",
                        marginBottom: "10px",
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        navigate(`/pwa/edit-pwa/${_id}`);
                      }}
                    >
                      {creatives.icon ? (
                        <img
                          src={showPreviewIcon(creatives.icon)}
                          alt="icon"
                          style={{
                            width: 56,
                            height: 56,
                            borderRadius: "50%",
                            marginRight: "5px",
                            flex: "0 0 auto",
                          }}
                        />
                      ) : (
                        <Box
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            width: 56,
                            height: 56,
                            background: "#F6F7FB",
                            borderRadius: "50%",
                            marginRight: "5px",
                            flex: "0 0 auto",
                          }}
                        >
                          <ImageIcon />
                        </Box>
                      )}
                      <Box sx={{ marginLeft: "12px" }}>
                        <Typography
                          sx={{
                            fontSize: "14px",
                            fontFamily: "var(--cygreRegular), sans-serif",
                            lineHeight: "20px",
                          }}
                        >
                          {appName}
                        </Typography>
                        {status && (
                          <Box sx={{ borderBottom: "none" }}>
                            <RenderPWAStatus pwaStatus={status} />
                          </Box>
                        )}
                      </Box>
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <MenuTableButton getPwas={getPwas} id={_id} pwaMeta={metaTags} />
                    </Box>
                  </Box>
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    <TypographyStyled sx={{ fontFamily: "var(--cygreMedium), sans-serif" }} variant="subtitle2">
                      {t("domain")}:
                    </TypographyStyled>
                    <Typography
                      sx={{
                        fontSize: "14px",
                        fontWeight: "400",
                        fontFamily: "var(--cygreRegular), sans-serif",
                        marginLeft: "7px",
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        window.open(`https://${domain?.name}`, "_blank");
                      }}
                    >
                      {domain?.name || "-"}
                    </Typography>
                  </Box>
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    <TypographyStyled variant="subtitle2">{t("vertical")}:</TypographyStyled>
                    <Typography
                      sx={{
                        fontSize: "14px",
                        fontWeight: "400",
                        fontFamily: "var(--cygreRegular), sans-serif",
                        marginLeft: "7px",
                      }}
                    >
                      {categoryVertical || "-"}
                    </Typography>
                  </Box>
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    <TypographyStyled variant="subtitle2">{t("tracker")}:</TypographyStyled>
                    <Typography
                      sx={{
                        fontSize: "14px",
                        fontWeight: "400",
                        fontFamily: "var(--cygreRegular), sans-serif",
                        marginLeft: "7px",
                      }}
                    >
                      {trackerValue || "-"}
                    </Typography>
                  </Box>
                  <Box sx={{ marginTop: "auto" }}>
                    <ActionButton domain={domain?.name} getPwas={getPwas} status={status} id={_id} />
                  </Box>
                </CardContent>
              </Card>
            </Grid>
          );
        })
      )}
    </Grid>
  );
};

const TypographyStyled = styled(Typography)(() => {
  return {
    color: "#6D6777",
    fontWeight: 500,
    fontFamily: "var(--cygreRegular), sans-serif",
  };
});
