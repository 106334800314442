import { Box, Grid } from "@mui/material";
import Deps from "assets/icons/Deps";
import Installs from "assets/icons/Installs";
import Opens from "assets/icons/Opens";
import Regs from "assets/icons/Regs";
import Ring from "assets/icons/Ring";
import Unique from "assets/icons/Unique";
import Preloader from "components/GlobalPreloader/Preloader";
import WidgetCard from "components/WidgetCard";
import useFetch from "hooks/useFetch";
import { useEffect, useState } from "react";

interface IStatisticWidget {
  totalInstallCount?: number;
}

export const getToken = () => {
  if (typeof window !== "undefined") {
    return localStorage.getItem("authToken") || "";
  }
};

const StatisticWidget: React.FC<IStatisticWidget> = ({ totalInstallCount }) => {
  const token = getToken();
  const [clicks, setClicks] = useState<any>([]);
  const [stats, setStats] = useState({ clicks: 0, campaign_unique_clicks: 0, regs: 0, deposits: 0 });
  const [isLoaded, setIsLoaded] = useState(false);

  const { triggerFetch: getReport } = useFetch({
    url: "tracking/keitaro-proxy",
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
    body: {
      method: "POST",
      uri: "/report/build",
      requestBody: {
        range: {
          timezone: "Europe/Madrid",
          interval: "7_days_ago",
        },
      },
    },
  });

  const buildReport = async () => {
    const res = await getReport();
    setClicks(res?.data?.rows || []);
    setIsLoaded(true);
  };

  useEffect(() => {
    void buildReport();
  }, []);

  useEffect(() => {
    const aggregatedStats = clicks.reduce(
      (acc: any, item: any) => {
        return {
          clicks: acc.clicks + (item.clicks || 0),
          campaign_unique_clicks: acc.campaign_unique_clicks + (item.campaign_unique_clicks || 0),
          regs: acc.regs + (item.regs || 0),
          deposits: acc.deposits + (item.deposits || 0),
        };
      },
      { clicks: 0, campaign_unique_clicks: 0, regs: 0, deps: 0 }
    );

    setStats(aggregatedStats);
  }, [clicks]);

  return (
    <>
      <Grid
        container
        spacing={2}
        sx={{
          position: "relative",
          flexWrap: { xs: "nowrap", sm: "wrap" },
          width: { xs: `calc(100vw * 2.5)`, sm: "100%" },
          marginBottom: "24px",
        }}
      >
        {!isLoaded && (
          <Preloader
            customStyles={{
              position: "absolute",
              top: "0",
              left: "0",
              width: "100%",
              height: "100%",
              padding: "0",
              margin: "0",
              border: 0,
              background: "rgba(255, 255, 255, 0.6)",
            }}
          />
        )}
        <Grid item xs={7} sm={12 / 3} lg={12 / 4} xl={12 / 5}>
          <WidgetCard title={"uniques"} value={stats.campaign_unique_clicks | 0} icon={<Unique />} />
        </Grid>
        <Grid item xs={7} sm={12 / 3} lg={12 / 4} xl={12 / 5}>
          <WidgetCard title={"installs"} value={totalInstallCount! | 0} icon={<Installs />} />
        </Grid>
        <Grid item xs={7} sm={12 / 3} lg={12 / 4} xl={12 / 5}>
          <WidgetCard title={"opens"} value={stats.clicks | 0} icon={<Opens />} />
        </Grid>
        <Grid item xs={7} sm={12 / 3} lg={12 / 4} xl={12 / 5}>
          <WidgetCard title={"regs"} value={stats.regs | 0} icon={<Regs />} />
        </Grid>
        <Grid item xs={7} sm={12 / 3} lg={12 / 4} xl={12 / 5}>
          <WidgetCard title={"deps"} value={stats.deposits | 0} icon={<Deps />} />
        </Grid>
      </Grid>
    </>
  );
};

export default StatisticWidget;
