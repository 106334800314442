import React, { ChangeEvent, useEffect, useState } from "react";
import CustomButton from "components/Button";
import { Box, Grid, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
// import GooglePlayLogo from "@/assets/icons/GooglePlayLogo";
import Upload from "assets/icons/Upload";
import CustomSelectComponent from "components/Select";
import CustomInput from "components/Input";
import Search from "assets/icons/Search";
import OurApp from "assets/icons/OurApp";
import SaveTab from "assets/icons/SaveTab";
import CopyGPlayModal from "./CopyGPlayModal";
import OwnPicture from "./OwnPicture";
import useVerticals from "mokcup/verticals";
import CustomTabs from "components/PWATabs/CustomTabs";
import useFetch from "hooks/useFetch";
import { useAppStore } from "store/appStore";
import { ICreative, useCreativesUplodStore } from "store/Creatives";
import showPreviewIcon from "utils/showPreviewImage";
import useFetchFormData from "hooks/useFetchFormData";
import { AppDataMockup } from "interfaces/mockupApp";
import useSetupStore from "store/setupSettingsStore";
import { useAuthStore } from "store/authStore";
import Save from "assets/icons/Save";
import ToastMessage from "components/ToastMessage";
import "styles/toastStyles.css";
import "react-toastify/dist/ReactToastify.css";
import SwitchComponent from "components/Checkbox";
import { getToken } from "utils/getToken";

const getOrientationStyles = (preview: string) => {
  const img = new Image();
  img.src = preview;

  if (img.width > img.height) {
    return {
      width: "200px",
      height: "auto",
    };
  } else {
    return {
      width: "auto",
      height: "200px",
    };
  }
};

export default function CreativesTab({ setValue }: { setValue: (value: number) => void }) {
  const { appData, saveAppData } = useAppStore();
  const [valueCreatives, setValueCreatives] = useState(0);
  const [images, setImages] = useState<ICreative[]>([]);

  const [ourSuggestions, setOurSuggestion] = useState<ICreative[]>([]);
  const [openModal, setOpenModal] = useState(false);
  const [openOPModal, setOPModal] = useState(false);
  const { t } = useTranslation();
  const verticals = useVerticals();
  const token = getToken();

  const { creativesUpload } = useCreativesUplodStore();
  const { user, setUser } = useAuthStore();

  const [searchValue, setSearchValue] = useState("");
  const [creativesData, setCreativesData] = useState([]);
  const [savedCreatives, setSavedCreatives] = useState<ICreative[]>(user?.creatives || []);

  const setStepStatus = useSetupStore((state) => state.setStepStatus);

  useEffect(() => {
    setStepStatus("creatives", "inprogress");
    return () => {
      setStepStatus("creatives", "ready");
    };
  }, [setStepStatus]);

  const { loading, triggerFetch } = useFetch({
    url: "creatives/",
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  const { loading: loadingroup, triggerFetch: creativesGroup } = useFetch({
    url: "creatives/groups",
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  const { triggerFetch: addedUserCreatives, error: addUserCreativesError } = useFetchFormData({
    url: "profile/added-user-creatives",
    method: "PUT",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  const { triggerFetch: deleteUserCreatives } = useFetchFormData({
    url: "profile/delete-user-creatives",
    method: "DELETE",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  const { triggerFetch: getUserInfo } = useFetch({
    url: "profile/user-info",
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  const userInfo = async () => {
    const res = await getUserInfo();
    if (res.data) {
      localStorage.setItem("userData", JSON.stringify(res.data));
      setUser(res.data);
    }
  };
  const tabs = [
    {
      icon: <Upload color={valueCreatives === 0 ? "#F4701B" : "#6D6777"} />,
      label: t("myUploads"),
    },
    {
      icon: <SaveTab color={valueCreatives === 1 ? "#F4701B" : "#6D6777"} />,
      label: t("saved"),
    },
    {
      icon: <OurApp color={valueCreatives === 2 ? "#F4701B" : "#6D6777"} />,
      label: t("ourSuggestions"),
    },
  ];

  useEffect(() => {
    if (valueCreatives === 0) {
      setImages(creativesUpload);
    } else if (valueCreatives === 1) {
      setImages(savedCreatives);
    } else if (valueCreatives === 2) {
      setImages(ourSuggestions);
    }
  }, [valueCreatives, creativesUpload, ourSuggestions, savedCreatives]);

  useEffect(() => {
    getCreatives();
  }, [appData?.categoryVertical]);

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value.toLowerCase();
    setSearchValue(value);

    if (value.trim() === "") {
      const filteredSuggestions = creativesData
        .filter((suggestion: any) => {
          const selectedVertical = verticals.find((vertical) => vertical.value === appData?.categoryVertical);
          return selectedVertical ? suggestion.group_type === selectedVertical.id : false;
        })
        .map((suggestion: any) => ({
          icon: suggestion.images.icon || null,
          previews: suggestion.images.previews || [],
        }));

      setOurSuggestion(filteredSuggestions || []);
      return;
    }

    const filteredSuggestions = creativesData.filter((item: any) =>
      item.group_tags.some((tag: string) => tag.toLowerCase().includes(value))
    );

    const suggestions = filteredSuggestions.map((suggestion: any) => ({
      icon: suggestion.images?.icon || null,
      previews: suggestion.images?.previews || [],
    }));

    setOurSuggestion(suggestions || []);
  };

  async function getCreatives() {
    const result = await creativesGroup();

    setCreativesData(result.data);

    const filteredSuggestions = result.data
      .filter((suggestion: any) => {
        const selectedVertical = verticals.find((vertical) => vertical.value === appData?.categoryVertical);

        return selectedVertical ? suggestion.group_type === selectedVertical.id : "gambling";
      })
      .map((suggestion: any) => ({
        icon: suggestion.images.icon || null,
        previews: suggestion.images.previews || [],
      }));

    setOurSuggestion(filteredSuggestions || []);
  }

  const handleInputChange = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    field: keyof AppDataMockup
  ) => {
    const { value } = event.target;
    saveAppData({ [field]: value });
  };

  const handleSelectChange = (event: ChangeEvent<{ value: unknown }>, field: keyof AppDataMockup) => {
    const { value } = event.target;

    saveAppData({ [field]: value as string });
  };

  const handleCheckboxChange = (event: any, field: keyof AppDataMockup) => {
    const { checked } = event.target;
    saveAppData({ [field]: checked });
  };

  const handleUpdatePwa = async () => {
    setValue(2);
    localStorage.setItem("lastTabValue", "2");
  };

  const handleSaveCreative = (imageSet: ICreative) => {
    saveAppData({
      creatives: {
        ...appData.creatives,
        icon: imageSet.icon,
        previews: imageSet.previews,
      },
    });
  };

  const handleUserCreative = async (creative: ICreative) => {
    const isAlreadySaved = savedCreatives.some((item) => item.icon === creative.icon);

    if (isAlreadySaved) {
      const data = new FormData();
      if (typeof creative.icon === "string") {
        data.append("creativeNameToRemove", creative.icon);
        const response = await deleteUserCreatives(data);
        if (response.success) {
          userInfo();
          setSavedCreatives(savedCreatives.filter((item) => item.icon !== creative.icon));
        } else {
          ToastMessage({ mode: "error", message: response.message });
        }
      }
    } else {
      const data = new FormData();
      if (creative.icon) {
        data.append("icon", creative.icon);
      }
      if (creative.previews && creative.previews.length > 0) {
        creative.previews.forEach((preview) => {
          data.append("previews", preview);
        });
      }
      setSavedCreatives([...savedCreatives, creative]);
      try {
        const res = await addedUserCreatives(data);
        // console.log("res", res);
        if (res) {
          userInfo();
          setSavedCreatives([...savedCreatives, creative]);
        }
        console.log("Ошибка при добавлении creative:", addUserCreativesError);
        if (addUserCreativesError) {
          ToastMessage({ mode: "error", message: addUserCreativesError });
          console.log("Ошибка при добавлении creative:", addUserCreativesError);
        }
      } catch (error) {
        console.error("Ошибка при добавлении creative:", error);
      }
    }
  };

  return (
    <>
      <Box
        sx={{
          border: "1px solid #EEEEEE",
          borderRadius: "8px",
          display: "flex",
          flexDirection: "column",
          width: "100%",
          padding: "0 27px",
        }}
      >
        <Box
          sx={{
            maxWidth: "530px",
          }}
        >
          <Box>
            <Typography
              sx={{
                fontFamily: "var(--cygreMedium), sans-serif",
                color: "#2E263D",
                fontSize: "16px",
                lineHeight: "32px",
              }}
            >
              {t("creatives")}
            </Typography>
            <Typography
              sx={{
                fontFamily: "var(--cygreRegular), sans-serif",
                color: "#6D6777",
                fontSize: "14px",
                lineHeight: "19px",
              }}
            >
              {t("selectsetimages")}
            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: { xs: "column", sm: "row" },
              alignItems: "center",
              justifyContent: "space-between",
              paddingTop: "24px",
            }}
          >
            <Box
              sx={{
                width: "100%",
              }}
            >
              <CustomButton
                startIconSrc={
                  <Box sx={{ paddingRight: "5px" }}>
                    <Upload color={"#6D6777"} />
                  </Box>
                }
                width="100%"
                textButton={t("downloadown")}
                customBgColor="transparent"
                customHoverColor="transparent"
                customBorderColor="#C9D0D8"
                customColor="#6D6777"
                onClick={() => setOPModal(true)}
                customBorderRadius="8px"
                height={40}
                customFontSize="15px"
              />
            </Box>

            <Box
              sx={{
                width: "100%",
                marginLeft: { xs: 0, sm: "24px" },
                marginTop: { xs: "16px", sm: 0 },
              }}
            >
              {/* <CustomButton
                startIconSrc={
                  <Box sx={{ paddingRight: "2px" }}>
                    <GooglePlayLogo />
                  </Box>
                }
                textButton={t("copygoogleplay")}
                customBgColor="transparent"
                customHoverColor="transparent"
                onClick={() => setOpenModal(true)}
                customBorderColor="#C9D0D8"
                customColor="#6D6777"
                customBorderRadius="8px"
                height={40}
                customFontSize="15px"
              /> */}
            </Box>
          </Box>
          <Box sx={{ margin: "16px 0" }}>
            <CustomSelectComponent
              title={t("category")}
              options={verticals}
              backgroundColor="#F6F7F9"
              noBorder={true}
              value={appData.categoryVertical}
              onChange={(e: any) => handleSelectChange(e, "categoryVertical")}
            />
          </Box>
          <Box sx={{ marginTop: "24px" }}>
            <CustomInput
              margin="normal"
              required
              fullWidth
              id="youtubeVideoLink"
              height="40px"
              placeholder={t("enterLinkOrFrame")}
              name="rating"
              label={t("youTubevideo")}
              value={appData!.youtubeVideoLink}
              onChange={(e) => handleInputChange(e, "youtubeVideoLink")}
              customBgColor="#F6F7F9"
            />
          </Box>
          <Box
            sx={{
              marginTop: "16px",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Typography>{t("videoSound")}</Typography>
            <SwitchComponent
              checked={appData!.soundVideo}
              onChange={(e: React.ChangeEvent<HTMLInputElement>, checked: boolean) =>
                handleCheckboxChange(e, "soundVideo")
              }
            />
          </Box>
        </Box>
        <Box
          sx={{
            borderTop: "1px solid #EEEEEE",
            paddingTop: "24px",
            marginTop: "24px",
          }}
        >
          <Box sx={{ marginBottom: "12px", maxWidth: "630px" }}>
            <CustomInput
              margin="normal"
              required
              fullWidth
              id="search"
              height="40px"
              placeholder={t("search")}
              name="search"
              value={searchValue}
              onChange={handleSearchChange}
              customBgColor="#F6F7F9"
              iconEnd={<Search />}
            />
          </Box>
          <Box
            sx={{
              borderRadius: "8px",
              height: "48px",
              background: "#F6F7FB",
              maxWidth: "630px",
            }}
          >
            <CustomTabs width="100%" value={valueCreatives} setValue={setValueCreatives} tabs={tabs} t={t} />
          </Box>
          <Grid
            container
            spacing={1}
            rowSpacing={0}
            sx={{
              maxWidth: "100%",
              // display: "flex",
              // flexWrap: "wrap",
              // justifyContent: "space-between",
              paddingTop: "24px",
            }}
          >
            {images &&
              images.map((imageSet: any, index: number) => (
                <Grid item xs={12} lg={12 / 2} xl={12 / 3}>
                  <Box
                    onClick={() => {
                      handleSaveCreative(imageSet);
                    }}
                    key={index}
                    sx={{
                      cursor: "pointer",
                      // paddingTop: "16px",
                      display: "flex",
                      flexDirection: "column",
                      borderWidth: "1px",
                      borderStyle: "solid",
                      padding: "8px",
                      borderRadius: "8px",
                      height: "100%",
                      borderColor: appData.creatives.icon === imageSet.icon ? "#F4701B" : "#EEEEEE",
                      backgroundColor: appData.creatives.icon === imageSet.icon ? "#F6F7FB" : "#fff",
                      // width: { xs: "100%", sm: "calc(50% - 16px)", md: "calc(33.33% - 16px)" },
                    }}
                  >
                    <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                      <img
                        style={{
                          width: "100%",
                          height: "auto",
                          maxWidth: "70px",
                          maxHeight: "70px",
                          objectFit: "cover",
                          borderRadius: "10px",
                          aspectRatio: "1",
                        }}
                        src={showPreviewIcon(imageSet.icon)}
                        alt="icon"
                      />
                      <Box
                        onClick={(event) => {
                          event.stopPropagation();
                          handleUserCreative(imageSet);
                        }}
                      >
                        <Save
                          color={savedCreatives.some((item) => item.icon === imageSet.icon) ? "#F4701B" : "#fff"}
                          colorStroke={
                            savedCreatives.some((item) => item.icon === imageSet.icon) ? "#F4701B" : "#6D6777"
                          }
                        />
                      </Box>
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        flexWrap: "nowrap",
                        flexDirection: "row",
                        width: "100%",
                        paddingTop: "10px",
                        justifyContent: "flex-start",
                        overflowX: "auto",
                        flex: "1 1 auto",
                        gap: "4px",
                        alignItems: "center",
                      }}
                    >
                      {imageSet.previews &&
                        imageSet.previews.map((preview: any, index: number) => {
                          const img = new Image();
                          img.src = preview;
                          const aspectRatio = img.width / img.height;
                          const isPortrait = aspectRatio < 1;
                          return (
                            <img
                              key={index}
                              style={{
                                // padding: "4px 4px 0 0",
                                objectFit: "contain",
                                aspectRatio: aspectRatio,
                                // width: "100%",
                                maxHeight: "150px",
                                maxWidth: isPortrait ? "100px" : "80%",
                                borderRadius:
                                  index === 0
                                    ? "8px 0 0 8px"
                                    : index === imageSet.previews.length - 1
                                      ? "0 8px 8px 0"
                                      : "0",
                                // ...getOrientationStyles(preview),
                              }}
                              src={showPreviewIcon(preview)}
                              alt="preview"
                            />
                          );
                        })}
                    </Box>
                  </Box>
                </Grid>
              ))}
          </Grid>
        </Box>
        <Box sx={{ width: "160px", margin: "24px 0" }}>
          <CustomButton
            textButton={t("next")}
            height={40}
            customBgColor="#F4701B"
            customHoverColor="#F4701B"
            customBorderRadius="8px"
            customFontSize="14px"
            onClick={handleUpdatePwa}
          />
        </Box>
      </Box>
      <CopyGPlayModal open={openModal} setOpen={setOpenModal} />
      <OwnPicture open={openOPModal} setOpen={setOPModal} />
    </>
  );
}
