import React, {useEffect, useRef} from "react";
import MainInfo from "./components/MainInfo/MainInfo";
import Vertical from "./components/Vertical/Vertical";
import Description from "./components/Description/Description";
import DataSecurity from "./components/DataSecurity/DataSecurity";
import Rating from "./components/Rating/Rating";
import ContactDeveloper from "./components/ContactDeveloper/ContactDeveloper";
import ClipLoader from "react-spinners/ClipLoader";
import "./styles/global.scss";

import {IPwaData} from "./interfaces";
import {CookieBanner} from "./components/CookieBanner";

type IDuckSayPWA = {
    pwaData?: IPwaData;
    testPwaDomain?: string;
    isMockPWA?: boolean;
    isPreview?: boolean;
    loading?: boolean;
    currentGeo?: string;
};

const PWA: React.FC<IDuckSayPWA> = ({pwaData: pwa, testPwaDomain, isPreview, loading, currentGeo}) => {
    const [defferedPrompt, setDeferredPrompt] = React.useState<any>(null);

    useEffect(() => {
        const handleBeforeInstallPrompt = (e: Event) => {
            e.preventDefault();
            setDeferredPrompt(e);
        };

        window.addEventListener("beforeinstallprompt", handleBeforeInstallPrompt);

        return () => {
            window.removeEventListener("beforeinstallprompt", handleBeforeInstallPrompt);
        };
    }, []);

    return (
        <>
            {!loading ? (
                <>
                    <div className="container">
                        {pwa && (
                            <>
                                <MainInfo
                                    defferedPrompt={defferedPrompt}
                                    setDefferedPrompt={setDeferredPrompt}
                                    pwa={pwa}
                                    testPwaDomain={testPwaDomain}
                                    currentGeo={currentGeo}
                                />
                                <div className="wrapper">
                                    <div className="grid-container">
                                        <div className="grid-item item-large">
                                            <div className="pb-16">
                                                <Vertical pwa={pwa}/>
                                            </div>
                                            <div className="pb-16">
                                                <Description pwa={pwa}/>
                                            </div>
                                            <div className="pb-16">
                                                <DataSecurity pwa={pwa}/>
                                            </div>
                                            <div className="pb-16">
                                                <Rating pwa={pwa}/>
                                            </div>
                                        </div>
                                        <div className="grid-item item-small">
                                            <ContactDeveloper pwa={pwa}/>
                                        </div>
                                    </div>
                                </div>
                            </>
                        )}
                    </div>
                    {pwa?.filesCookies ? <CookieBanner/> : null}</>
            ) : (
                <div className={"loader-cover"}>
                    <ClipLoader
                        color={"#0e77c2"}
                        loading={true}
                        // cssOverride={override}
                        size={150}
                        aria-label="Loading Spinner"
                        data-testid="loader"
                    />
                </div>
            )}
        </>
    )
};

export default PWA;
