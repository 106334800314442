import { create } from "zustand";
import { AppDataMockup } from "interfaces/mockupApp";
import { textsToTranslate } from "utils/en";
import { createJSONStorage, persist } from "zustand/middleware";

interface AppState {
  isChanged: boolean;
  appData: AppDataMockup;
  saveAppData: (newData: Partial<AppDataMockup>, isChanged?: boolean) => void;
  clearAppData: () => void;
  resetAppData: () => void;
  getDefaultAppData: () => AppDataMockup;
  intiAppData: (data: AppDataMockup) => void;
}

export const emptyAppData: AppDataMockup = {
  appName: "Simple app",
  author: "Author",
  ad: false,
  category: ["Comics"],
  size: "17",
  description: "",
  dateOfUpdates: "10/10/2024",
  numberOfInst: "7000",
  developerSite: "developer.com",
  developerEmail: "developer@email.com",
  adressCompany: "M16F, Dongwoo Bldg, 328, Teheran-ro, Gangnam-gu, Seoul, 06212, Rep. of KOREA",
  linkPolicy: "Privacy policy",
  descriptionPC: "Description for pc",
  rating: "0.00",
  amountReviews: "1500",
  ratings: [0, 0, 0, 0, 0],
  language: "",
  translations: textsToTranslate,
  creatives: { icon: null, previews: null },
  comments: [],
  categoryVertical: "gambling",
  youtubeVideoLink: "https://link-video-youtube",
  soundVideo: false,
  token: "",
  onlyAndroid: false,
  sendAnoutherGeo: false,
  linkRedirect: "",
  wightSource: "",
  inappropriateTraffic: "",
  geo: [],
  ignoringCloaca: false,
  fullscreen: false,
  filesCookies: false,
  getParams: false,
  trafficBack: "",
  categoryTracking: "",
  offerLink: "https://",
  status: "pause",
  blackLink: "",
  country: "Ukraine",
  userId: "",
  updatedAt: "",
  createdAt: "",
  price: "",
  flowId: 0,
  fbpixel: "",
  fbtoken: "",
};

const useAppStore = create<AppState>()(
  persist(
    (set) => ({
      isChanged: false,
      appData: { ...emptyAppData },
      intiAppData: (data: AppDataMockup) => {
        set({ appData: data, isChanged: false });
      },
      saveAppData: (newData, isChanged = true) =>
        set((state) => {
          const updatedAppData = { ...state.appData, ...newData };
          return { appData: updatedAppData, isChanged: Boolean(isChanged) };
        }),
      clearAppData: () => {
        set({ appData: { ...emptyAppData } });
      },
      resetAppData: () => {
        set({ appData: { ...emptyAppData } });
      },
      getDefaultAppData: () => emptyAppData,
    }),
    {
      name: "pwa-tmp-storage",
      storage: createJSONStorage(() => localStorage),
    }
  )
);

interface ErrorState {
  errors: { [key: string]: string };
  setError: (field: string, message: string) => void;
  clearError: (field: string) => void;
  clearAllErrors: () => void;
}

const useErrorAppStore = create<ErrorState>((set) => ({
  errors: {},
  setError: (field, message) =>
    set((state) => ({
      errors: { ...state.errors, [field]: message },
    })),
  clearError: (field) =>
    set((state) => {
      const newErrors = { ...state.errors };
      delete newErrors[field];
      return { errors: newErrors };
    }),
  clearAllErrors: () => set({ errors: {} }),
}));

export { useAppStore, useErrorAppStore };
