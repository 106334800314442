import { Box, Button, Grid, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import useFetch from "hooks/useFetch";
import StatisticWidget from "components/StatisticWidget";
import PushTable from "components/PushTable";
import { useNavigate } from "react-router";
import { IPwaStatisticsItem } from "pages/DashboardPage";
import StatisticPushWidget from "components/StatisticPushWidget";

export const getToken = () => {
  if (typeof window !== "undefined") {
    return localStorage.getItem("authToken") || "";
  }
};

const Pushes = () => {
  const { t } = useTranslation();
  const token = getToken();
  const navigate = useNavigate();
  const [totalInstallCount, setTotalInstallCount] = useState<number>(0);
  const { triggerFetch: getInstalls } = useFetch({
    url: "statistics/",
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  useEffect(() => {
    async function setInstalls() {
      const res = await getInstalls();

      const totalInstallCount = res?.data.items.reduce(
        (sum: number, app: IPwaStatisticsItem) => sum + app.installCount,
        0
      );
      setTotalInstallCount(totalInstallCount);
    }
    void setInstalls();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  async function createPush() {
    navigate("/push/create");
  }

  return (
    <Box>
      <Grid container spacing={2}>
        <Grid
          item
          xs={12}
          lg={12}
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography variant="h4" sx={{ fontSize: "24px" }}>
            {t("pushNotifications")}
          </Typography>
          <Box>
            <Button
              sx={{
                color: "#fff",
                backgroundColor: "#F4701B",
                textTransform: "uppercase",
                "&:hover": {
                  backgroundColor: "#F0670B",
                },
              }}
              className="create_btn"
              variant="contained"
              aria-label="create pwa"
              size="medium"
              onClick={createPush}
            >
              {t("createPush")}
            </Button>
          </Box>
        </Grid>

        <Box sx={{ display: { xs: "none", sm: "flex", width: "100%", padding: "16px" } }}>
          <StatisticPushWidget />
        </Box>
        <Grid item xs={12} lg={12}>
          <PushTable />
        </Grid>
      </Grid>
    </Box>
  );
};

export default Pushes;
