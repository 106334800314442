import { Navigate } from "react-router";
import { FC, ReactNode } from "react";

interface iProtectedRouteHOCProps {
  redirectPath?: string;
  children?: ReactNode;
}

export const AdminProtectedRouteHOC: FC<iProtectedRouteHOCProps> = ({ redirectPath = "*", children }) => {
  const userLocalstorageData = localStorage.getItem("userData");
  const { isAdmin } = userLocalstorageData ? JSON.parse(userLocalstorageData) : null;

  return isAdmin ? <>{children}</> : <Navigate to={redirectPath} replace />;
};
