import React, { ChangeEvent, useEffect, useState } from "react";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Box,
  FormControlLabel,
  FormControl,
  RadioGroup,
  Radio,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useTranslation } from "react-i18next";
import { usePushStore } from "store/pushStore";
import StandartSets from "components/StandartSets";
import IntervalSets from "components/IntervalSets";
import { IPwa } from "interfaces/pwas";
import useFetch from "hooks/useFetch";
import { getToken } from "components/Stats";
import StatsSelectComponent from "components/StatsSelect";
import { useAppStore } from "store/appStore";

export interface ILang {
  language: string;
  name: string;
}

interface IMainSection {
  setSelectedPwa: (pwa: IPwa) => void;
}

export default function MainSection({ setSelectedPwa }: IMainSection) {
  const { t } = useTranslation();
  const [pwas, setPwas] = useState<Array<IPwa>>([]);

  const { pushData, savePushData } = usePushStore();

  const token = getToken();
  const handleSelectChange = (event: ChangeEvent<{ value: unknown }>) => {
    const selectedAppName = event.target.value as string;
    const selectedPwa = pwas.find((pwa) => pwa.appName === selectedAppName);

    if (selectedPwa) {
      setSelectedPwa(selectedPwa);
      savePushData({
        pwaId: selectedPwa?._id,
        icon: selectedPwa?.creatives.icon,
        origin: selectedPwa?.domain?.name || "",
      });
    }
  };

  const { loading, triggerFetch } = useFetch({
    url: "pwa/user",
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  async function getPwas() {
    const result = await triggerFetch();

    setPwas(result?.data?.items || null);
  }

  useEffect(() => {
    void getPwas();
  }, []);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;

    savePushData({
      delivery: value,
    });
  };

  return (
    <Accordion
      defaultExpanded
      sx={{
        boxShadow: "none",
        border: "1px solid #EEEEEE",
        borderRadius: "8px",
      }}
    >
      <AccordionSummary sx={{ height: "48px" }} expandIcon={<ExpandMoreIcon />}>
        <Typography
          sx={{ lineHeight: "32px", fontSize: "16px", color: "#2E263D", fontFamily: "var(--cygreMedium), sans-serif" }}
        >
          {t("main")}
        </Typography>
      </AccordionSummary>
      <Box>
        <AccordionDetails sx={{ padding: "0" }}>
          <Box
            sx={{
              padding: "8px 16px 16px",
              maxWidth: "480px",
            }}
          >
            <StatsSelectComponent
              title={t("selectPwa")}
              options={pwas}
              backgroundColor="#F6F7F9"
              noBorder={true}
              value={pwas}
              onChange={(e: any) => handleSelectChange(e)}
            />
          </Box>
          <Box
            sx={{
              width: "100%",
              display: "flex",
              flexDirection: { xs: "column", sm: "row" },
              padding: "8px 16px 16px",
              justifyContent: "space-between",
              borderTop: "1px solid #EEEEEE",
            }}
          >
            <Box>
              <FormControl>
                <RadioGroup
                  aria-labelledby="demo-controlled-radio-buttons-group"
                  name="controlled-radio-buttons-group"
                  value={pushData.delivery}
                  onChange={handleChange}
                >
                  <FormControlLabel
                    sx={{
                      paddingBottom: { xs: "8px", lg: 0 },

                      "& .MuiFormControlLabel-label": {
                        color: "#2E263D",
                        fontSize: "14px",
                      },
                    }}
                    value="now"
                    control={<Radio />}
                    label={t("nowDelivery")}
                  />
                  <FormControlLabel
                    sx={{
                      paddingBottom: { xs: "8px", lg: 0 },

                      "& .MuiFormControlLabel-label": {
                        color: "#2E263D",
                        fontSize: "14px",
                      },
                    }}
                    value="scheduled"
                    control={<Radio />}
                    label={t("scheduleDelivery")}
                  />
                  {/*<FormControlLabel*/}
                  {/*  sx={{*/}
                  {/*    "& .MuiFormControlLabel-label": {*/}
                  {/*      color: "#2E263D",*/}
                  {/*      fontSize: "14px",*/}
                  {/*    },*/}
                  {/*  }}*/}
                  {/*  value="intervalMailing"*/}
                  {/*  control={<Radio />}*/}
                  {/*  label={t("intervalCampaign")}*/}
                  {/*/>*/}
                </RadioGroup>
              </FormControl>
            </Box>
            <Box>
              {pushData.delivery === "scheduled" && <StandartSets />}
              {pushData.delivery === "intervalMailing" && <IntervalSets />}
            </Box>
          </Box>
        </AccordionDetails>
      </Box>
    </Accordion>
  );
}
