import { usePromocodesStore } from "store/promocodesStore";
import { getToken } from "utils/getToken";
import useFetch from "hooks/useFetch";
import { Box, Collapse } from "@mui/material";
import PromocodeDataPicker from "components/AdminPromocodeCreateSection/PromocodeDataPicker";
import CustomButton from "components/Button";
import CustomInput from "components/Input";
import CustomSelectComponent from "components/Select";
import { amountSelectOptions, statusSelectOptions } from "./PromocodesConstatns";
import { t } from "i18next";
import { toast } from "react-toastify";
import { Dispatch, SetStateAction } from "react";

interface IPromocodeFormProps {
  isPromocodeFormOpen: boolean;
  handleIsNewPromocode: Dispatch<SetStateAction<boolean>>;
}

const PromocodeCreationForm = ({ isPromocodeFormOpen, handleIsNewPromocode }: IPromocodeFormProps) => {
  const token = getToken();
  const { promocodeData, savePromocodeData, clearPromocodeData } = usePromocodesStore();
  const { name, amount, status } = promocodeData;

  const { loading, triggerFetch: createPromocode } = useFetch({
    url: "admin/promocodes",
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  const handleFormChange = async (
    event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = event.target;
    if (name === "promocodeName") {
      const promocodeUpperCaseName = value.toUpperCase();
      savePromocodeData({ name: promocodeUpperCaseName });
    } else if (name === "amount") {
      savePromocodeData({ amount: Number(value) });
    } else {
      savePromocodeData({ status: value });
    }
  };

  const handleSubmit = async () => {
    const newPromocode = await createPromocode(promocodeData);
    if (newPromocode.success) {
      handleIsNewPromocode(true);
      clearPromocodeData();
      toast.success(t("successfulPromocodeCreation"));
    } else {
      toast.error(t("unsuccessfulPromocodeCreation"));
    }
  };

  return (
    <Collapse in={isPromocodeFormOpen}>
      <Box sx={{ display: "flex", flexDirection: "column", gap: "8px", width: "50%", marginBottom: "20px" }}>
        <CustomInput
          required
          fullWidth
          id="promocodeName"
          height="40px"
          placeholder={t("promocodeNameInputPlaceholder")}
          value={name}
          name="promocodeName"
          label={t("promocodename")}
          onChange={(event) => {
            handleFormChange(event);
          }}
          customBgColor="#F6F7F9"
        />
        <Box sx={{ display: "flex", alignItems: "center", gap: "24px" }}>
          <Box sx={{ width: `calc(100% - 48px)` }}>
            <CustomSelectComponent
              options={amountSelectOptions}
              name="amount"
              placeholder={t("promocodeAmountSelectPalceholder")}
              title={t("amount")}
              value={amount}
              onChange={(event: any) => handleFormChange(event)}
              noBorder
              backgroundColor="#F6F7F9"
            />
          </Box>
          <Box sx={{ width: `calc(100% - 48px)` }}>
            <CustomSelectComponent
              options={statusSelectOptions}
              name="status"
              placeholder={t("promocodeStatusSelectPalceholder")}
              title={t("status")}
              value={status}
              onChange={(event: any) => handleFormChange(event)}
              noBorder
              backgroundColor="#F6F7F9"
            />
          </Box>
        </Box>
        <Box sx={{ display: "flex", alignItems: "flex-end", gap: "24px" }}>
          <PromocodeDataPicker />
          <CustomButton
            textButton={t("create")}
            height={40}
            width={`calc(100% - 48px)`}
            customBgColor="#F4701B"
            customHoverColor="#F4701B"
            customBorderRadius="8px"
            customFontSize="12px"
            customPadding="0"
            loading={loading}
            onClick={handleSubmit}
          />
        </Box>
      </Box>
    </Collapse>
  );
};

export default PromocodeCreationForm;
