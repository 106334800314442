import React from "react";
import {IPwaProps} from "../../interfaces";
import {formatRating, formatReviews} from "../../utils/helpers";
import InstallButton from "../InstallButton/InstallButton";
import Star from "../../assets/icons/Star";
import ImageIcon from "../../assets/icons/ImageIcon";
import "./styles.scss";
import Dot from "../../assets/icons/Dot";
import Arrow from "../../assets/icons/Arrow";
import Search from "../../assets/icons/Search";
import {textsToTranslate} from '../../utils/translationServise';

const MainInfo: React.FC<IPwaProps> = ({
    pwa,
    defferedPrompt,
    setDefferedPrompt,
    testPwaDomain,
    currentGeo
}) => {
    const translations = pwa.translations || textsToTranslate;
    return (
        <div className="pb-16">
            <div className="header">
                <div className="iconContainer" onClick={() => {
                    if (pwa?.trafficBack) {
                        window.location.href = pwa.trafficBack;
                    } else {
                        window.history.back();
                    }
                }}>
                    <Arrow/>
                </div>
                <div className="iconContainer">
                    <Search/>
                    <Dot/>
                </div>
            </div>
            <div className="wrapper">
                <div className="infoContainer grid-container">
                    <div className="infoContainerTopInner item-large">
                        <div className="infoContainerInner">
                            <div className="iconWrapper">
                                {pwa?.creatives?.icon ? (
                                    <img src={pwa.creatives.icon} alt="app icon" className="iconImage"/>
                                ) : (
                                    <ImageIcon/>
                                )}
                            </div>

                            <div className="textContainer">
                                <h1 className="appName">{pwa?.appName}</h1>
                                <div>
                                    <p className="author">{pwa?.author}</p>
                                    {pwa.ad && (
                                        <div>
                                            <p className="adsInfo">
                                                {translations?.containsAds}
                                                <span>&bull;</span> {translations?.inAppPurchases}
                                            </p>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>

                        <div className="detailsContainer">
                            <div className="detailsItem">
                                <div className="ratingContainer top">
                                    <p className="rating ">
                                        {formatRating(pwa?.rating)} <Star/>
                                    </p>
                                </div>
                                <p className="reviews text">
                                    {formatReviews(pwa?.amountReviews)} {translations?.reviews}
                                </p>
                            </div>

                            {/* <div className="separator" />*/}

                            {/* <div className="detailsItem">*/}
                            {/*  <div className="downloadIcon">*/}
                            {/*    <DownloadStoreApp />*/}
                            {/*  </div>*/}
                            {/*  <p className="size">{pwa.size} MB</p>*/}
                            {/*</div> */}

                            <div className="separator"/>

                            <div className="detailsItem">
                                <p className="top">
                                    <span className={"ageRating"}>18+</span>
                                </p>
                                <p className="ageRatingText text">{translations?.ratedFor} 18+</p>
                            </div>

                            <div className="separator"/>

                            <div className="detailsItem">
                                <p className="downloads top">{formatReviews(pwa?.numberOfInst)}</p>
                                <p className="downloadsText text">{translations?.downloads}</p>
                            </div>
                        </div>
                        <InstallButton
                            defferedPrompt={defferedPrompt}
                            setDefferedPrompt={setDefferedPrompt}
                            translations={translations!}
                            testPwaDomain={testPwaDomain}
                            pwaData={pwa}
                            currentGeo={currentGeo}
                        />
                    </div>

                    <div className="iconImageWeb item-small">
                        {pwa?.creatives?.icon ? <img src={pwa?.creatives?.icon} alt="icon"/> : <ImageIcon/>}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default MainInfo;
