interface StatusSelectOption {
  name: string;
  value: string;
  label: string;
}

interface AmountSelectOption {
  name: string;
  value: number;
  label: string;
}

export const statusSelectOptions: StatusSelectOption[] = [
  { name: "status", value: "active", label: "active" },
  { name: "status", value: "inactive", label: "inactive" },
];

export const amountSelectOptions: AmountSelectOption[] = [
  { name: "amount", value: 5, label: "5$" },
  { name: "amount", value: 10, label: "10$" },
  { name: "amount", value: 15, label: "15$" },
  { name: "amount", value: 20, label: "20$" },
  { name: "amount", value: 25, label: "25$" },
  { name: "amount", value: 30, label: "30$" },
  { name: "amount", value: 35, label: "35$" },
  { name: "amount", value: 40, label: "40$" },
  { name: "amount", value: 45, label: "45$" },
  { name: "amount", value: 50, label: "50$" },
  { name: "amount", value: 55, label: "55$" },
  { name: "amount", value: 60, label: "60$" },
  { name: "amount", value: 65, label: "65$" },
  { name: "amount", value: 70, label: "70$" },
  { name: "amount", value: 75, label: "75$" },
  { name: "amount", value: 80, label: "80$" },
  { name: "amount", value: 85, label: "85$" },
  { name: "amount", value: 90, label: "90$" },
  { name: "amount", value: 95, label: "95$" },
  { name: "amount", value: 100, label: "100$" },
];
