import { Box } from "@mui/material";
import { useEffect, useState } from "react";
import StatisticWidget from "components/StatisticWidget";
import AccountsTable from "components/AccountsTable";
import MyBarChart from "components/Charts";
import StatsMap from "components/StatsMap";
import useFetch from "hooks/useFetch";
import { getToken } from "utils/getToken";
import { fetchCountries } from "utils/fetchCountries";

export interface IPwaStatisticsItem {
  pwaId: string;
  installCount: number;
  totalAmount: number;
  icon: string;
  name: string;
  country: string;
  updatedAt: string;
  geo: string[];
}

const Dashboard = () => {
  const token = getToken();
  const [statistics, setStatistics] = useState<IPwaStatisticsItem[]>([]);
  const [totalInstallCount, setTotalInstallCount] = useState<number | undefined>(undefined);
  const { triggerFetch } = useFetch({
    url: "statistics/",
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  useEffect(() => {
    async function getAccountsData() {
      const res = await triggerFetch();

      const totalInstallCount = res?.data.items.reduce(
        (sum: number, app: IPwaStatisticsItem) => sum + app.installCount,
        0
      );
      const sortedItems = res?.data?.items?.sort(
        (a: IPwaStatisticsItem, b: IPwaStatisticsItem) =>
          new Date(b.updatedAt).getTime() - new Date(a.updatedAt).getTime()
      );
      setTotalInstallCount(totalInstallCount);
      setStatistics(sortedItems || []);
    }

    void getAccountsData();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      <Box
        sx={{
          width: "100%",
          overflow: "auto",
          padding: "2px 2px",
        }}
      >
        <StatisticWidget totalInstallCount={totalInstallCount} />
      </Box>
      <Box
        sx={{
          border: "1px solid #EEEEEE",
          borderRadius: "8px",
          display: "flex",
          flexDirection: "column",
          width: "100%",
          padding: { xs: "10px 0px", lg: "20px 27px" },
        }}
      >
        <StatsMap />
      </Box>
      <Box
        sx={{
          border: "1px solid #EEEEEE",
          borderRadius: "8px",
          padding: { xs: "10px 0px", lg: "20px 27px" },
          width: "100%",
          margin: "24px 0",
        }}
      >
        <MyBarChart />
      </Box>
      <Box
        sx={{
          border: "1px solid #EEEEEE",
          borderRadius: "8px",
          maxWidth: "750px",
          padding: { xs: "10px 0px", lg: "20px 27px" },
        }}
      >
        <AccountsTable statistics={statistics} />
      </Box>
    </div>
  );
};

export default Dashboard;
