import { Dispatch, SetStateAction } from "react";
import { Typography, Table, TableBody, TableCell, TableHead, TableRow, TablePagination, Box } from "@mui/material";
import styled from "@emotion/styled";
import { useTranslation } from "react-i18next";
import { IPromocode } from "interfaces/promocode";
import moment from "moment";
import PaidIcon from "@mui/icons-material/Paid";
import LensIcon from "@mui/icons-material/Lens";
import PermIdentityIcon from "@mui/icons-material/PermIdentity";
import PromocodeActionButtons from "./AdminPromoActionButtons";

interface AdminUsersTabProps {
  promocodesArr: IPromocode[];
  page: number;
  totalPromocodes: number;
  handleChangePage: (event: React.MouseEvent<HTMLButtonElement> | null, page: number) => void;
  handleStatus: Dispatch<SetStateAction<boolean>>;
}

const TypographyStyled = styled(Typography)(() => {
  return {
    color: "#6D6777",
    fontWeight: 500,
    fontFamily: "var(--cygreRegular), sans-serif",
  };
});

const AdminPromocodesTab: React.FC<AdminUsersTabProps> = ({
  promocodesArr,
  totalPromocodes,
  page,
  handleChangePage,
  handleStatus,
}) => {
  const { t } = useTranslation();

  return (
    <>
      <Table aria-label="simple table">
        <TableHead>
          <TableRow
            sx={{
              backgroundColor: "#F6F7FB",
            }}
          >
            <TableCell
              sx={{
                paddingLeft: "36px",
              }}
            >
              <TypographyStyled>{t("promocodename")}</TypographyStyled>
            </TableCell>
            <TableCell>
              <TypographyStyled>{t("amount")}</TypographyStyled>
            </TableCell>
            <TableCell>
              <TypographyStyled>{t("status")}</TypographyStyled>
            </TableCell>
            <TableCell>
              <TypographyStyled>{t("expirationdate")}</TypographyStyled>
            </TableCell>
            <TableCell sx={{ width: "300px" }}>
              <TypographyStyled>{t("activatedby")}</TypographyStyled>
            </TableCell>
            <TableCell>
              <TypographyStyled
                style={{
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                {t("actions")}
              </TypographyStyled>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {promocodesArr?.length === 0 ? (
            <TableRow>
              <TableCell colSpan={6}>
                <Typography variant="subtitle2">{t("noitems")}</Typography>
              </TableCell>
            </TableRow>
          ) : (
            promocodesArr.map(({ _id, name, amount, status, expiredAt, activatedBy }) => {
              const formattedDate = moment(expiredAt).format("DD.MM.YYYY HH:mm:ss");
              return (
                <TableRow key={_id}>
                  <TableCell>
                    <Box sx={{ display: "flex", alignItems: "center", gap: "12px", paddingLeft: "18px" }}>
                      <PaidIcon
                        sx={{
                          color: "#6D6777",
                          width: "26px",
                          height: "26px",
                        }}
                      />

                      <Typography
                        sx={{
                          fontSize: "14px",
                          fontWeight: "400",
                          fontFamily: "var(--cygreRegular), sans-serif",
                        }}
                      >
                        {name}
                      </Typography>
                    </Box>
                  </TableCell>
                  <TableCell sx={{ borderBottom: "none" }}>
                    <Typography
                      sx={{
                        fontSize: "14px",
                        fontWeight: "400",
                        fontFamily: "var(--cygreRegular), sans-serif",
                      }}
                    >
                      {amount}
                    </Typography>
                  </TableCell>
                  <TableCell sx={{ borderBottom: "none" }}>
                    <Box sx={{ display: "flex", alignItems: "center", gap: "8px" }}>
                      <LensIcon
                        sx={{
                          color: status === "active" ? "#16B364" : "#F4701B",
                          width: "10px",
                          height: "10px",
                        }}
                      />
                      <Typography
                        sx={{
                          fontSize: "14px",
                          fontWeight: "400",
                          fontFamily: "var(--cygreRegular), sans-serif",
                        }}
                      >
                        {status}
                      </Typography>
                    </Box>
                  </TableCell>
                  <TableCell sx={{ borderBottom: "none" }}>
                    <Typography
                      sx={{
                        fontSize: "14px",
                        fontWeight: "400",
                        fontFamily: "var(--cygreRegular), sans-serif",
                      }}
                    >
                      {formattedDate}
                    </Typography>
                  </TableCell>
                  <TableCell sx={{ borderBottom: "none" }}>
                    {activatedBy ? (
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          gap: "10px",
                        }}
                      >
                        <PermIdentityIcon
                          sx={{
                            color: "#6D6777",
                            width: "18px",
                            height: "18px",
                          }}
                        />
                        <Typography
                          sx={{
                            fontSize: "14px",
                            fontWeight: "400",
                            fontFamily: "var(--cygreRegular), sans-serif",
                          }}
                        >
                          {activatedBy}
                        </Typography>
                      </Box>
                    ) : (
                      <Typography
                        sx={{
                          fontSize: "14px",
                          fontWeight: "400",
                          fontFamily: "var(--cygreRegular), sans-serif",
                        }}
                      >
                        {"—"}
                      </Typography>
                    )}
                  </TableCell>
                  <TableCell>
                    <PromocodeActionButtons
                      promocodeId={_id}
                      promocodeStatus={status}
                      handleCurrentStatusChange={handleStatus}
                    />
                  </TableCell>
                </TableRow>
              );
            })
          )}
        </TableBody>
      </Table>
      <TablePagination
        rowsPerPageOptions={[]}
        component="div"
        count={totalPromocodes}
        rowsPerPage={20}
        page={page}
        labelRowsPerPage={null}
        labelDisplayedRows={() => ""}
        onPageChange={handleChangePage}
        sx={{
          "& .MuiTablePagination-spacer": {
            display: "none",
          },
          "& .MuiTablePagination-toolbar": {
            padding: 0,
            justifyContent: "flex-start",
          },
        }}
      />
    </>
  );
};

export default AdminPromocodesTab;
