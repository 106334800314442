import { create } from "zustand";

interface IdStore {
  id: string | null;
  _id: string | null; // Новое свойство для хранения id
  lastSelectedPwaId: string | null;
  setId: (id: string) => void;
  clearId: () => void; // Новый метод для очистки id
  setLastId: (id: string | null) => void;
}

const useIdStore = create<IdStore>((set) => ({
  id: null,
  _id: null, // Инициализация нового свойства
  lastSelectedPwaId: null,
  setId: (id) => set({ id }),
  clearId: () => set({ id: null }), // Реализация метода очистки id
  setLastId: (id) => set({ lastSelectedPwaId: id }),
}));

export default useIdStore;
