import { IAuth } from "interfaces/auth";
import { ICountry } from "interfaces/push";

//  token

export const saveToken = (token: string): void => {
  return localStorage.setItem("authToken", token || "");
};

// validate form
export const validateForm = (data: IAuth) => {
  const errors: IAuth = {
    email: "",
    password: "",
  };

  if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(data.email)) {
    errors.email = "Enter the correct email address";
  }

  if (data.password.length < 6) {
    errors.password = "The password must contain at least 6 characters";
  }

  return errors;
};

export const getCountryFlags = (geoArray: string[], countries: any) => {
  return geoArray.map((geoCode) => {
    const country = countries?.find((c: any) => geoCode.includes(c.code));
    return country ? country.flag : null;
  });
};
