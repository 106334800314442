import React from "react";

function EditIcon({ color = "#6D6777" }: any) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="none" viewBox="0 0 18 18">
      <path
        fill={color}
        d="M2 16h1.261L13.498 5.764l-1.261-1.262L2 14.738V16zm-.596 1.5a.874.874 0 01-.644-.26.874.874 0 01-.26-.644v-1.732a1.8 1.8 0 01.527-1.275L13.691.93c.15-.138.318-.244.5-.319A1.5 1.5 0 0114.766.5c.2 0 .395.036.583.107.188.07.354.184.499.34l1.221 1.236c.155.145.266.311.332.5.066.188.099.377.099.565 0 .201-.034.393-.103.576-.069.183-.178.35-.328.501L4.412 16.973a1.8 1.8 0 01-1.276.527H1.404zM12.856 5.144l-.62-.642 1.262 1.261-.642-.62z"
      ></path>
    </svg>
  );
}

export default EditIcon;
