import React, { useEffect, useRef, useState } from "react";
import { AppBar, styled, IconButton, Box } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import { useTranslation } from "react-i18next";
import Profile from "assets/Profile_pic.png";
import FlagRU from "assets/flag-ru.png";
import FlagUA from "assets/flag-ua.png";
import FlagUK from "assets/flag-uk.png";
import Notifications from "assets/notifications.png";
import Wallet from "assets/wallet.png";
import { useAuthStore } from "store/authStore";
import Logo from "components/Logo";
import useSetupStore from "store/setupSettingsStore";
import useIdStore from "store/pwaId";
import { useNavigate } from "react-router";
import Dropdown from "components/Dropdown";
import useFetch from "hooks/useFetch";
import { getToken } from "utils/getToken";

const flags = {
  ua: FlagUA,
  en: FlagUK,
  ru: FlagRU,
};

type Option = {
  name: string;
  component?: () => React.JSX.Element;
  action: () => void;
  selected?: boolean;
};

interface HeaderProps {
  setMobileSidebarOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const Header = ({ setMobileSidebarOpen }: HeaderProps) => {
  const token = getToken();
  const AppBarStyled = styled(AppBar)(({ theme }) => ({
    background: theme.palette.background.paper,
    justifyContent: "space-between",
    backdropFilter: "blur(2px)",
    [theme.breakpoints.up("lg")]: {
      minHeight: "70px",
    },
    flexDirection: "row",
    height: "56px",
    padding: "20px",
    zIndex: 10,
    boxShadow:
      "0px 0px 4px -1px rgba(0, 0, 0, 0.2), 0px 1px 5px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12)",
  }));

  const [isOpen, setIsOpen] = useState(false);
  const [isLangOpen, setIsLangOpen] = useState(false);
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const { clearAuthData } = useAuthStore();
  const { clearId } = useIdStore();
  const setSettingProcess = useSetupStore((state) => state.setSettingProcess);
  const resetSteps = useSetupStore((state) => state.resetAllSteps);
  const savedUserString = typeof window !== "undefined" && localStorage.getItem("userData");
  const savedUser = savedUserString ? JSON.parse(savedUserString || "{}") : null;
  const { updateUserData, user } = useAuthStore();
  const isAdmin = user?.isAdmin;

  const goTo = (path: string) => {
    setSettingProcess(false);
    resetSteps();
    clearId();
    navigate(path);
    if (path === "/") {
      clearAuthData();
    }
  };

  const dropDownList = [
    {
      name: t("profile"),
      action: () => goTo("/profile"),
    },
    isAdmin && {
      name: t("admin"),
      action: () => goTo("/admin"),
    },
    {
      name: t("replenishment"),
      action: () => goTo("/payment"),
    },
    {
      name: t("exit"),
      action: () => goTo("/"),
    },
  ].filter(Boolean) as Option[];

  const langList = [
    {
      name: "UA",
      action: async () => {
        await i18n.changeLanguage("ua");
      },
      component: () => (
        <div style={flagStyle}>
          <img width={24} height={24} src={FlagUA} alt="flag" />
          <span style={{ marginLeft: "10px" }}>UA</span>
        </div>
      ),
      selected: i18n.language === "ua",
    },
    {
      name: "EN",
      action: async () => {
        await i18n.changeLanguage("en");
      },
      component: () => (
        <div style={flagStyle}>
          <img width={24} height={24} src={FlagUK} alt="flag" />
          <span style={{ marginLeft: "10px" }}>EN</span>
        </div>
      ),
      selected: i18n.language === "en",
    },
    {
      name: "RU",
      action: async () => {
        await i18n.changeLanguage("ru");
      },
      component: () => (
        <div style={flagStyle}>
          <img width={24} height={24} src={FlagRU} alt="flag" />
          <span style={{ marginLeft: "10px" }}>RU</span>
        </div>
      ),
      selected: i18n.language === "ru",
    },
  ];

  const selectedFlag = flags[(i18n?.language as "ua" | "en" | "ru") || "ua"];

  const { triggerFetch: getWallet } = useFetch({
    url: "profile/wallet",
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  const walletRequest = async () => {
    const res = await getWallet();
    if (res?.data) {
      updateUserData(res.data);
    }
  };

  const intervalRef = useRef<NodeJS.Timeout | null>(null);

  useEffect(() => {
    intervalRef.current = setInterval(
      () => {
        void walletRequest();
      },
      1000 * 60 * 2
    );

    return () => {
      if (intervalRef.current) {
        clearInterval(intervalRef.current);
      }
    };
  }, []);

  return (
    <AppBarStyled position="relative" color="inherit">
      <div style={{ display: "flex", alignItems: "center" }}>
        <Box sx={{ display: { xs: "block", md: "none" } }}>
          <IconButton onClick={() => setMobileSidebarOpen(true)}>
            <MenuIcon />
          </IconButton>
        </Box>
        <Logo />
      </div>

      <div
        style={{
          display: "flex",
          alignItems: "center",
        }}
      >
        <Box
          onClick={() => navigate("/payment")}
          sx={{
            marginRight: "10px",
            display: {
              xs: "none",
              md: "flex",
            },
            alignItems: "center",
            cursor: "pointer",
          }}
        >
          <img width={24} height={24} src={Wallet} alt="wallet" />
          <span
            style={{
              fontWeight: 600,
              fontSize: "18px",
              lineHeight: "20px",
              color: "#F4701B",
              paddingLeft: "4px",
            }}
          >
            {savedUser && savedUser.wallet}
          </span>
        </Box>

        <div
          onClick={() => {
            setIsOpen(false);
            setIsLangOpen(!isLangOpen);
          }}
          style={{ cursor: "pointer", display: "flex", alignItems: "center" }}
        >
          <img width={24} height={24} src={selectedFlag} alt="flag" />
          <Dropdown options={langList} setIsOpen={setIsLangOpen} isOpen={isLangOpen} />
        </div>

        <img
          width={15}
          height={19}
          src={Notifications}
          alt="notifications"
          style={{ marginTop: "2px", marginLeft: "10px" }}
        />

        <div
          onClick={() => {
            setIsOpen(!isOpen);
            setIsLangOpen(false);
          }}
          style={{ cursor: "pointer", display: "flex", alignItems: "center" }}
        >
          <img
            width={40}
            height={40}
            src={(savedUser && savedUser.icon) || Profile}
            alt="profile"
            style={{ borderRadius: "50%", marginLeft: "10px" }}
          />
          <Dropdown options={dropDownList} setIsOpen={setIsOpen} isOpen={isOpen} />
        </div>
      </div>
    </AppBarStyled>
  );
};

const flagStyle = {
  display: "flex",
  alignItems: "center",
};

export default Header;
