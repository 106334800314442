import { Box, Grid, TablePagination } from "@mui/material";
import AdminUsersTab from "components/AdminUsersTab";
import CautionModal from "components/CautionModal";
import SearchBar from "components/SearchBar";
import useFetch from "hooks/useFetch";
import useIsMobile from "hooks/useIsMobile";
import { IUser } from "interfaces/auth";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import useCautionStore from "store/cautionStore";
import { getToken } from "utils/getToken";

const UsersPage = () => {
  const [users, setUsers] = useState<Array<IUser>>([]);
  const [filter, setFilter] = useState<string>("");
  const [page, setPage] = useState(0);
  const rowsPerPage = 20;
  const { isUserDeleteModalOpen, userToDelete, handleDeleteUserChange, closeModal } = useCautionStore();
  const { t } = useTranslation();
  const token = getToken();
  const isMobile = useIsMobile();

  const filtredUser = filter
    ? users.filter(
        (user) =>
          user.telegram?.toLowerCase().includes(filter.toLowerCase()) ||
          user.email?.toLowerCase().includes(filter.toLowerCase())
      )
    : users;

  const currentUsersRows = filtredUser.slice(page * rowsPerPage, (page + 1) * rowsPerPage);

  const { triggerFetch } = useFetch({
    url: "admin/users",
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  const { loading, triggerFetch: deleteUser } = useFetch({
    url: `admin/users/${userToDelete?.id}`,
    method: "DELETE",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  const getAllUsers = async () => {
    const { users } = await triggerFetch();
    setUsers(users ? users.reverse() : []);
  };

  const handleSearch = (value: string) => {
    setFilter(value);
    setPage(0);
  };

  const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number): void => {
    setPage(newPage);
  };

  const handleUserDelete = async () => {
    try {
      await deleteUser();
      setUsers((prevUsers) => {
        const updatedUsers = prevUsers.filter((user) => user._id !== userToDelete?.id);
        if (currentUsersRows.length === 1 && page > 0) {
          setPage(page - 1);
        }
        return updatedUsers;
      });
      handleDeleteUserChange(null);
      closeModal();
      toast.success(t("successfulUserDeletion"));
    } catch {
      toast.error(t("unsuccessfulUserDeletion"));
    }
  };

  useEffect(() => {
    getAllUsers();
  }, []);

  return (
    <Box>
      <Box sx={{ width: { sm: "auto" } }}>
        <Grid
          item
          xs={12}
          lg={12}
          sx={{
            display: "flex",
            justifyContent: isMobile ? "center" : "space-between",
            alignItems: "normal",
            marginBottom: isMobile ? "15px" : "0",
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              width: "100%",
              marginBottom: "20px",
            }}
          >
            <SearchBar onSearch={handleSearch} placeholder={t("searchuser")} />
            <TablePagination
              rowsPerPageOptions={[]}
              component="div"
              count={filtredUser.length}
              rowsPerPage={20}
              page={page}
              labelRowsPerPage={null}
              labelDisplayedRows={() => ""}
              onPageChange={handleChangePage}
              sx={{
                "& .MuiTablePagination-spacer": {
                  display: "none",
                },
                "& .MuiTablePagination-toolbar": {
                  padding: 0,
                  justifyContent: "flex-start",
                },
              }}
            />
          </Box>
        </Grid>
        <AdminUsersTab
          totalUsers={filtredUser.length}
          usersArr={currentUsersRows}
          page={page}
          handleChangePage={handleChangePage}
        />
      </Box>
      {isUserDeleteModalOpen && <CautionModal handleConfirm={handleUserDelete} isLoading={loading} />}
    </Box>
  );
};

export default UsersPage;
