import React from "react";
import IIcon from "interfaces/icon";

function Success({ color = "#F4701B", width = 125, height = 125 }: IIcon) {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M8.58075 12.1463L6.25775 9.823C6.11925 9.68467 5.94517 9.61383 5.7355 9.6105C5.526 9.60733 5.34875 9.67817 5.20375 9.823C5.05892 9.968 4.9865 10.1437 4.9865 10.35C4.9865 10.5563 5.05892 10.732 5.20375 10.877L7.948 13.6212C8.12883 13.8019 8.33975 13.8923 8.58075 13.8923C8.82175 13.8923 9.03267 13.8019 9.2135 13.6212L14.777 8.05775C14.9153 7.91925 14.9862 7.74517 14.9895 7.5355C14.9927 7.326 14.9218 7.14875 14.777 7.00375C14.632 6.85892 14.4563 6.7865 14.25 6.7865C14.0437 6.7865 13.868 6.85892 13.723 7.00375L8.58075 12.1463ZM10.0017 19.5C8.68775 19.5 7.45267 19.2507 6.2965 18.752C5.14033 18.2533 4.13467 17.5766 3.2795 16.7218C2.42433 15.8669 1.74725 14.8617 1.24825 13.706C0.749417 12.5503 0.5 11.3156 0.5 10.0017C0.5 8.68775 0.749333 7.45267 1.248 6.2965C1.74667 5.14033 2.42342 4.13467 3.27825 3.2795C4.13308 2.42433 5.13833 1.74725 6.294 1.24825C7.44967 0.749417 8.68442 0.5 9.99825 0.5C11.3123 0.5 12.5473 0.749333 13.7035 1.248C14.8597 1.74667 15.8653 2.42342 16.7205 3.27825C17.5757 4.13308 18.2528 5.13833 18.7518 6.294C19.2506 7.44967 19.5 8.68442 19.5 9.99825C19.5 11.3123 19.2507 12.5473 18.752 13.7035C18.2533 14.8597 17.5766 15.8653 16.7218 16.7205C15.8669 17.5757 14.8617 18.2528 13.706 18.7518C12.5503 19.2506 11.3156 19.5 10.0017 19.5ZM10 18C12.2333 18 14.125 17.225 15.675 15.675C17.225 14.125 18 12.2333 18 10C18 7.76667 17.225 5.875 15.675 4.325C14.125 2.775 12.2333 2 10 2C7.76667 2 5.875 2.775 4.325 4.325C2.775 5.875 2 7.76667 2 10C2 12.2333 2.775 14.125 4.325 15.675C5.875 17.225 7.76667 18 10 18Z"
        fill="#16B364"
      />
    </svg>
  );
}

export default Success;
