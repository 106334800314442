"use client";
import { Box } from "@mui/material";
import { useEffect, useState } from "react";
import { useAppStore } from "store/appStore";
import { translateText } from "utils/translationService";
import { textsToTranslate } from "utils/en";
import Eye from "assets/icons/Eye";
import { useTranslation } from "react-i18next";
import { ThemeProvider } from "@mui/material/styles";
import { baselightTheme } from "utils/theme/defaultColors";
import PhonePushContent from "components/PhonePush";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import PushIcon from "components/PushIcon";
import NewsletterContent from "components/NewsletterContent";
import PushButtons from "components/PushButtons";
import MainSection from "components/MainSection";
import Optional from "components/Optional";
import NewsletterSegmentation from "components/NewsletterSegmentation";
import { getToken } from "components/Stats";
import useFetch from "hooks/useFetch";
import PhoneMockup from "components/PhoneMockup";
import { usePushStore } from "store/pushStore";
import { useNavigate } from "react-router-dom";
import PushMockupModal from "components/PushMockupModal";
import { toast } from "react-toastify";
import { IPwa } from "interfaces/pwas";
import dayjs, { Dayjs } from "dayjs";

function PushCreatePage() {
  // const [translations, setTranslations] = useState({});
  const navigate = useNavigate();
  // const { appData } = useAppStore();
  const { pushData, savePushData, clearPushData } = usePushStore();
  const [openModal, setOpenModal] = useState(false);
  // const [selectedOsId, setSelectedOsId] = useState<string>("");
  const [selectedPwa, setSelectedPwa] = useState<IPwa | null>(null);
  // const [basicApiKey, setBasicApiKey] = useState<any>("");
  const token = getToken();
  // const [session, setSession] = useState("");

  // console.log("lang: ", pushData);

  useEffect(() => {
    return () => {
      clearPushData();
    };
  }, []);

  const { triggerFetch } = useFetch({
    url: `push/${selectedPwa?._id}`,
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  // const handleSaveDraft = () => {};
  const handleStartUp = async () => {
    const { pushName, headings, contents, pwaId, standartDate, standartTime } = pushData;
    let errorMessage = "";
    if (!pushName) {
      errorMessage = "Please select a Campaign name!";
    } else if (!headings) {
      errorMessage = "Please enter a title!";
    } else if (!contents) {
      errorMessage = "Please enter a message!";
    } else if (!pwaId) {
      errorMessage = "Please select PWA!";
    }

    if (errorMessage.length) {
      toast.error(errorMessage);
      return;
    }

    const pushToSend: any = pushData;

    const date = dayjs(standartDate);
    const isDateValid = date.isValid();

    if (isDateValid) {
      let hours = 0;
      let minutes = 0;

      if (standartTime !== "") {
        [hours, minutes] = standartTime.split(":").map(Number);
      }
      const scheduledDate = date.hour(hours).minute(minutes).second(0).millisecond(0);

      // Check if scheduled date is valid and in the future
      if (scheduledDate.isValid() && scheduledDate.isAfter(dayjs())) {
        pushToSend.scheduledDate = scheduledDate.valueOf();
      } else {
        console.error("Invalid or past scheduled date/time");
      }
    }

    const response = await triggerFetch(pushToSend);
    if (response?.success) {
      toast.success("Push notification sent successfully");
      navigate("/push");
    } else {
      toast.error(response.message);
    }
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Box
        sx={{
          width: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          marginBottom: "24px",
        }}
      >
        <PushIcon />
        <Box sx={{ display: { xs: "none", md: "block" } }}>
          <PushButtons useAltStyles={false} handleStartUp={handleStartUp} />
        </Box>
      </Box>

      <Box sx={{ display: "flex" }}>
        <Box sx={{ minWidth: { xs: "90%", lg: "680px" }, marginRight: { xs: "50px", md: "0" } }}>
          <Box sx={{ paddingBottom: "24px" }}>
            <MainSection setSelectedPwa={setSelectedPwa} />
          </Box>
          <Box sx={{ paddingBottom: "24px" }}>
            <NewsletterContent />
          </Box>
          <Box sx={{ paddingBottom: "24px" }}>
            <Optional />
          </Box>
          <Box sx={{ paddingBottom: "24px" }}>
            <NewsletterSegmentation />
          </Box>
          <PushButtons useAltStyles={true} handleStartUp={handleStartUp} />
        </Box>

        <Box
          sx={{
            display: { xs: "none", xl: "block" },
            marginLeft: "26px",
            border: "1px solid #EEEEEE",
            borderRadius: "8px",
            padding: "50px",
          }}
        >
          <PhoneMockup>
            <PhonePushContent isModal={false} />
          </PhoneMockup>
        </Box>

        <Box
          onClick={() => setOpenModal(true)}
          sx={{
            display: { xs: "flex", xl: "none" },
            width: 40,
            height: 40,
            borderBottomLeftRadius: "8px",
            borderTopLeftRadius: "8px",
            background: "#F4701B",
            marginTop: "71px",
            marginLeft: { xs: "-24px", md: "24px" },
            marginRight: "-24px",
            alignItems: "center",
            justifyContent: "center",
            cursor: "pointer",
            position: "sticky",
            top: "10px",
          }}
        >
          <Eye color={"#fff"} />
        </Box>
      </Box>

      <PushMockupModal openModal={openModal} setOpenModal={setOpenModal} />
    </LocalizationProvider>
  );
}
export default PushCreatePage;
