import CustomButton from "components/Button";
import { Box } from "@mui/material";
import { useTranslation } from "react-i18next";

interface IPushButtons {
  handleStartUp: () => void;
  useAltStyles?: boolean;
}

export default function PushButtons({ handleStartUp, useAltStyles = false }: IPushButtons) {
  const { t } = useTranslation();

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "flex-start",
        flexDirection: useAltStyles ? { xs: "column", sm: "row" } : { xs: "column", xl: "row" },
      }}
    >
      {/*
      TODO: Return later
      */}
      {/* <Box
        sx={{
          width: { xs: "100%", sm: "246px" },
          paddingRight: { xs: 0, sm: "8px" },
          paddingBottom: { xs: "8px", xl: 0 },
        }}
      >
        <CustomButton
          textButton={t("saveAsDraft")}
          height={40}
          width={"100%"}
          customColor="#6D6777"
          customBorderColor="#C9D0D8"
          customHoverColor="transparent"
          customBorderRadius="8px"
          customFontSize="16px"
          onClick={handleSaveDraft}
        />
      </Box> */}

      <Box sx={{ width: { xs: "100%", sm: "246px" } }}>
        <CustomButton
          textButton={t("schedule")}
          height={40}
          width={"100%"}
          customBgColor="#F4701B"
          customHoverColor="#F4701B"
          customBorderRadius="8px"
          customFontSize="16px"
          onClick={handleStartUp}
        />
      </Box>
    </Box>
  );
}
